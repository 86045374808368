<template>
  <section id="contact" class="my-img text-black">
    <b-container class=" ">
      <div class="py-2">
        <scrollactive>
          <b-row class="">
            <b-col lg="12" style="width: 100%; text-align: center">
              <h1
                class="font-weight-bolder align-text-center p-1 text-primary"
                id="app-title"
                style="text-transform: uppercase"
              >
                <strong>REGISTRATION PROCESS</strong>
              </h1>
            </b-col>

            <b-col
              md="12"
              class="align-text-center text-primary mt-2"
              style="margin-left: 60px; padding-right: 195px"
            >
              <h5>
                <span style="text-align: left; white-space: nowrap;">
                  1- Select your account type.
                </span>
                <span class="urdu" style="text-align: left; ">

                  &nbsp;&nbsp;&nbsp; اپنی اکاؤنٹ کی قسم منتخب کریں <span>۔</span> <span>۱</span></span
                >
              </h5>
              <h5>
                <span style="text-align: left; white-space: nowrap;">
                  2- Enter your personal information.
                </span>
                <span class="urdu"> اپنی ذاتی معلومات دیں<span>۔</span> <span>۲</span></span>
              </h5>
              <!-- <h6>
                <span style="text-align: left"> i: Your CNIC. </span>
                <span class="urdu">  آپ کا شناختی کارڈ </span>
              </h6>
              <h6>
                <span style="text-align: left"> ii: Your Name. </span>
                <span class="urdu"> آپ کا نام۔ </span>
              </h6>
              <h6>
                <span style="text-align: left"> iii: Your Mobile Number. </span>
                <span class="urdu"> آپ کا موبائل نمبر۔ </span>
              </h6>
              <h6>
                <span style="text-align: left">
                  iv: Password should be at least 8 characters long.
                </span>
                <span class="urdu">
                  پاسورڈ کم از کم 8 حروف کا ہونا چاہئے۔
                </span>
              </h6> -->
              <h5>
                <span style="text-align: left; white-space: nowrap;">
                  3- If you are family of shaheed.
                </span>
                <span class="urdu" style="text-align: left; "> اگر آپ شہید کے خاندان کے ہیں تو <span>۔</span> <span>۳</span></span>
              </h5>
              <h6>
                <span style="text-align: left; white-space: nowrap;">
                  a- Enter CNIC of shaheed.
                </span>
                <span class="urdu" style="margin-right: 18px;"> شہید کا شناختی کارڈ درج کریں <span> ۔</span> <span>ا</span></span>
              </h6>
              <h6>
                <span style="text-align: left; white-space: nowrap;">
                  b- Your relation with shaheed.
                </span>
                <span class="urdu" style="margin-right: 18px;"> آپ کا شہید سے تعلق <span>۔</span> <span>ب</span></span>
              </h6>
              <h5>
                <span style="text-align: left;white-space: nowrap; ">
                  4- Click on sign up button.
                </span>
                <span class="urdu">سائن اپ کے بٹن پر کلک کریں <span>۔</span> <span>۴</span></span>
              </h5>
              <h5 class="" style="text-align: left; white-space: nowrap;">
                <span>
                  5- Upon submitting the form 
                </span>
                <span class="urdu ">
                  فارم جمع ہونے پر آپ کی معلومات <span>۔</span> <span>۵</span>
                </span>
                   </h5>

              <h5 class="" style="text-align: left; white-space: nowrap;">
                <span>
                   your information will undergo
                </span>
                <span class="urdu ">
                   کی تصدیق کی جائے گی
                </span>
                   </h5>

                <h5  class="" style="text-align: left; white-space: nowrap; margin-bottom: 0px ;">
                <span>
                verification, and you will 
                </span>
                <span   class="urdu " >
                  تصدیق ہونے پر آپ کو بذریعہ ایس  
                </span>
                </h5>

                <h5  class="" style="text-align: left; white-space: nowrap; margin-bottom: 0px ;">
                <span>
                be notified via SMS on approval.
                </span>
                <span   class="urdu " >
                   ایم ایس آگاہ کر دیا جائے گا 
                </span>
                </h5>
  
            </b-col>
            <b-col sm="12" class="pt-5">
              <!-- <h1
                class="font-weight-bolder align-text-center text-primary"
                id="app-title"
                style="text-transform: uppercase; text-align: center"
              >
                <strong>{{ $t("navbar.guide") }}</strong>
              </h1> -->
              <div class="m-1 mx-auto pr-1 video-container">
                <video
                  controls
                  autoplay
                  muted
                  class="responsive-video"
                  style="border: 1px solid black"
                >
                  <source  :src="videoPath" type="video/mp4" />
                </video>
              </div>
            </b-col>
          </b-row>
        </scrollactive>
      </div>
    </b-container>
  </section>
</template>

<style></style>

<script>
import { $themeConfig } from "@themeConfig";
export default {
  data() {
    return {
      appName: "",
      appLogoImage: "",
      videoPath: '/assets/videos/afp.mp4',
    };
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
};
</script>
<style scoped>
h5,
h6 {
  font-weight: 500;
  font-size: 14px !important;
  color: black;
}

h6 {
  padding-left: 15px;
}

.my-img {
  /* background: url("~@/assets/images/slider/green.png"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: None !important;
  background-blend-mode: multiply;
  height: 100%;
  width: 100%;
}
.urdu {
  font-family: "Noto Nastaliq Urdu", serif !important;
  padding-top: 0px;
  display: flex;
  justify-content: start;
  white-space: nowrap;
  
  @media (min-width: 671px) {
    white-space: normal;
    display: flex; 
    justify-content: flex-end;
    padding-top: -8px;
  }
}




.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (adjust as needed) */
  overflow: hidden;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
}

.responsive-video {
  position: absolute;
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 95%;
  object-fit: cover; /* Adjust the object-fit value as needed */
}

/* Media query for screens wider than 990px */
@media (min-width: 991px) {
  .responsive-video {
    width: 70% !important;
    height: 70% !important;
    top: 0% !important;
    left: 15% !important;
  }
  .video-container {
    margin-bottom: -10% !important;
  }
}
</style>
