<template>
  <span v-if="$mq === 'sm'">
    <b-card no-body>
      <b-card-title
        class="text-white font-weight-bolder bg-primary m-0 p-1 text-center"
      >
        <h1 class="font-weight-bolder text-white">
          <strong>{{ $t("application.title") }}</strong>
        </h1>
      </b-card-title>
      <b-card-body>
        <swiper
          class="swiper-responsive-breakpoints"
          :options="swiperOptionsMobile"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="data in cards_data"
            :key="data.id"
            style="cursor: pointer"
          >
            <span @click="swiperSelected(data)" class="text-center">
              <b-card
                footer-tag="footer"
                footer-bg-variant="primary"
                footer-text-variant="white"
                footer-border-variant="dark"
                style="
                  max-width: 30rem;
                  background-color: lightgrey;
                  border-radius: 30px;
                "
                no-body
              >
                <b-img
                  :src="data.img_1_path"
                  fluid
                  class="card-img w-100"
                  style="
                    padding-top: 12%;
                    align-items: center;
                    justify-content: center;
                    padding-left: 5%;
                  "
                />
                <div class="overlay-text">{{ data.app_name }}</div>
                <template #footer>
                  <h3 class="text-white text-center">
                    {{ data.app_name }}
                  </h3>
                </template>
                <div
                  class="pt-1"
                  style="
                    background-color: #094120 !important;
                    border-radius: 0px 0px 30px 30px;
                    padding-bottom: 5%;
                  "
                >
                  <h3
                    class="text-white text-center justify-content-center align-items-center"
                  >
                    {{ data.app_name }}
                  </h3>
                </div>
              </b-card>
            </span>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination" />
        </swiper>
      </b-card-body>
    </b-card>
  </span>
  <span v-else>
    <b-card no-body>
      <b-card-title
        class="text-white font-weight-bolder bg-primary m-0 p-1 text-center"
      >
        <h1 class="font-weight-bolder text-white">
          <strong>{{ $t("application.title") }}</strong>
        </h1>
      </b-card-title>
      <b-card-body id="crd">
        <swiper
          class="swiper-responsive-breakpoints"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide
            v-for="data in cards_data"
            :key="data.id"
            style="cursor: pointer; align-content: center; align-items: center"
          >
            <span @click="swiperSelected(data)" class="text-center">
              <b-card
                class="image-container my-card"
                footer-tag="footer"
                footer-bg-variant="primary"
                footer-text-variant="white"
                footer-border-variant="white"
                style="max-width: 30rem"
                no-body
                id="card"
              >
                <b-img
                  :src="data.img_1_path"
                  fluid
                  class="card-img w-100"
                  style="align-items: center; justify-content: center"
                  id="img"
                />
                <div class="overlay-text">{{ data.app_name }}</div>
                <!-- <template #footer>
                <h3 class="text-white text-center">
                  {{ data.app_name }}
                </h3>
              </template> -->
                <div
                  class="pt-1"
                  style="
                    background-color: #094120 !important;
                    border-radius: 0px 0px 30px 30px;
                    padding-bottom: 5%;
                  "
                >
                  <h3
                    class="text-white text-center justify-content-center align-items-center"
                  >
                    {{ data.app_name }}
                  </h3>
                </div>
              </b-card>
            </span>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination" />
        </swiper>
      </b-card-body>
    </b-card>
  </span>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";
import { BCard, BCardTitle, BCardBody } from "bootstrap-vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BCard,
    BCardTitle,
    BCardBody,
  },
  data() {
    return {
      cards_data: [
        // {
        //   id: 1,
        //   app_name: "Shuhada",
        //   link_name: "ShuhadaDashboard",
        //   img_1_path: require("@/assets/images/slider/2.svg"),
        //   img_2_path: require("@/assets/images/slider/w-02.svg"),
        //   // img_2_path : require("@/assets/images/slider/w-03.svg"),
        //   application_title: "application.shuhada",
        // },
        {
          id: 2,
          app_name: "Request for Assistance",
          link_name: "Petitions",
          img_1_path: require("@/assets/images/slider/rfa.jpeg"),
          img_2_path: require("@/assets/images/slider/w-03.svg"),
          application_title: "application.petition",
        },
        {
          id: 3,
          app_name: "No Objection Certificate",
          link_name: "Nocs",
          img_1_path: require("@/assets/images/slider/noc.jpg"),
          img_2_path: require("@/assets/images/slider/w-01.svg"),
          application_title: "application.nocLand",
        },
      ],
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 55,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {},
      },
      swiperOptionsMobile: {
        slidesPerView: 4,
        spaceBetween: 55,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {},
      },
    };
  },
  computed: {
    ...mapGetters({ getUser: "appData/getUser" }),
  },
  methods: {
    swiperSelected(data) {
      if (this.getUser) {
        if (
          this.getUser.role_data.code_name === "op" &&
          data.app_name === "Petition"
        ) {
          this.$router.push({ name: "ApproveUser" });
        } else {
          this.$router.push({ name: data.link_name });
        }
      }
    },
  },
};
</script>
<style>
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0%;
  width: 100%;
}

.swiper-pagination-bullet-active {
  background-color: #094019;
}

.swiper-pagination-bullet {
  background: #094019;
}

.swiper-wrapper {
  display: flex;
}

.swiper-pagination {
  position: relative;
  transition: 300ms opacity;
  z-index: 10;
  padding-top: 10px;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  font-weight: 700;
}
.image-container {
  position: relative;
  border-radius: 30px 30px 30px 30px;
}

#card {
  background-color: rgba(0, 0, 0, 1) !important;
}

#img {
  opacity: 0.9;
  border-radius: 30px 30px 0px 0px;
}

.image-container:hover #img {
  opacity: 0.3;
}

.image-container:hover .overlay-text {
  display: block;
}

.overlay-text {
  display: none;
  /* rest of styling for overlay text */
}

.swiper-main {
  display: flex;
  flex-direction: column;
  align-content: center;
  width: 300%;
}

.swiper-name {
  display: inline-block;
  vertical-align: top;
}

.internal-span {
  padding: 20px;
}

.card-img {
  width: 300px;
  height: 350px;
}

#crd {
  background-image: url("../../../src/assets/images/slider/RFA.png");
  background-size: cover;
  background-position: center;
  opacity: 1;
}
</style>
