<template>
  <div class="bg pt-2 pb-2 pl-3 pr-2">
    <b-row style="height: 100%">
      <b-col md="4">
        <b-col lg="12" style="width: 100%; text-align: center">
          <h1
            class="font-weight-bolder align-text-center p-1 text-white"
            id="app-title"
            style="text-transform: uppercase"
          >
            <strong>{{ $t("navbar.announcements") }}</strong>
          </h1>
        </b-col>
        <b-col lg="12" style="height: calc(100% - 45px)">
          <div class="m-1 pr-1" style="height: 100%">
            <dynamic-marquee
              reverse
              hoverPause
              :repeatMargin="400"
              :speed="{ type: 'duration', number: 15000 }"
            >
              <b-card
                no-body
                v-for="(item, index) in news"
                class="overflow-hidden mb-1"
                :key="index"
                style="height: calc(100% - 12px)"
              >
                <b-card-body class="p-50 ml-1 mr-1" style="height: 100%">
                  <b-row
                    class="align-items-center p-0 rounded"
                    style="background-color: #b3d2bb; height: 100%"
                  >
                    <b-col lg="2" md="4" sm="4">
                      <b-img
                        :src="item.image"
                        alt="News image"
                        class="my-img"
                      ></b-img>
                    </b-col>
                    <b-col lg="1"></b-col>
                    <b-col lg="9" md="8" sm="8" class="mt-1 mt-sm-1 pl-4">
                      <b-card-title
                        class="text-primary mt-1"
                        style="text-align: start"
                      >
                        {{ $t(item.title) }}
                      </b-card-title>
                      <b-card-sub-title
                        class="text-black mb-1"
                        style="color: black !important; text-align: start"
                      >
                        {{ $t(item.description) }}
                      </b-card-sub-title>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </dynamic-marquee>
          </div>
        </b-col>
      </b-col>
      <b-col md="8" class="px-4" style="height: 100%">
        <carousel-3d
          :controls-visible="true"
          :autoplay="true"
          :autoplay-timeout="3000"
          :display="5"
          :height="calculateCarouselHeight()"
        >
          <slide
            v-for="(slide, i) in slides"
            :index="i"
            :key="i"
            style="border-radius: 10px"
          >
            <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
              <img
                id="slide-img"
                :data-index="index"
                :class="{
                  current: isCurrent,
                  onLeft: leftIndex >= 0,
                  onRight: rightIndex >= 0,
                }"
                :src="slide.image"
                style="height: 100%; width: 100%"
              />
              <figcaption
                style="text-align: center; font-weight: 450; font-size: 1rem"
              >
                {{ slide.caption }}
              </figcaption>
            </template>
          </slide>
        </carousel-3d>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/images/banner/bb4b0ee70901b50a1be7d829910a568c.jpg"),
          caption:
            "Quaid-e-Azam Inspecting The First Honour Guard Of The 7/10th Baloch Regiment At Karachi - August 1947",
          borderRadius: "00% 0%",
        },
        {
          image: require("@/assets/images/banner/Ayub&Jinnah_Briskey.jpg"),
          caption:
            "Quaid-e-Azam Mohammad Ali Jinnah decorates Lieutenant Colonel M. Ahmad with the Military Cross for his services in Burma during World War II on March 20, 1948.",
          borderRadius: "0% 10%",
        },
        {
          image: require("@/assets/images/banner/EMnGKhnXsAA_vny.jpg"),
          caption: "Quaid-e-Azam with Major General Nazir Ahmad",
          borderRadius: "10% 10%",
        },
        {
          image: require("@/assets/images/banner/rY5dG2b_5jZ5z5-7.jpg"),
          caption: "Quaid-e-Azam reviews a guard of honour at Peshawar",
          borderRadius: "0% 20%",
        },
        {
          image: require("@/assets/images/banner/gen-ayub.jpg"),
          caption:
            "Gen Ayub Khan Army C-in-C reviews the second Republic Day on 23 March 1958",
          borderRadius: "20% 0%",
        },

        {
          image: require("@/assets/images/banner/maj-aziz.jpg"),
          caption: "Heroes who sacrificed their today for our better tommorow.",
          borderRadius: "0% 10%",
        },
      ],
      news: [
        {
          id: 1,
          image: require("@/assets/images/slider/03.jpg"),
          title: "news.title",
          description: "news.description",
        },
        {
          id: 2,
          image: require("@/assets/images/slider/03.jpg"),
          title: "news.title",
          description: "news.description",
        },
      ],
    };
  },
  methods: {
    calculateCarouselHeight() {
      
      const availableHeight = window.innerHeight * 0.3; 
      return availableHeight;
    },
  },
  computed: {
    ...mapGetters({ isLoggedIn: "appData/isLoggedIn" }),
  },
};
</script>

<style scoped>
#slide-img:hover {
  transform: scale(1.2);
  transition: transform 0.3s ease;
}

.bg {
  background: url("~@/assets/images/slider/green.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: None !important;
  background-blend-mode: multiply;
  height: 50%;
  width: 100%;
}

.my-img {
  border-radius: 10%;
  width: auto;
  height: 100px;
}

.carousel-3d-controls .next {
  color: white !important;
}
.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 15px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}

.bordered-col {
  border: 1px solid rgb(255, 255, 255);
}
</style>
