<template>
  <div class="p-0">
    <b-navbar toggleable="lg" type="dark" class="navbar-custom p-0 fixed-top" style="background-color: rgb(216, 216, 216)">
      <b-navbar-brand to="/home" class="res d-flex d-flex-row">
        <img src="@/assets/images/logo/afp-logo.png" class="bgImg" alt="" />
        <h5 class="text-black" style="font-size: 1.5rem; padding-top: 15px !important; font-weight: 600;">
          {{ appName }}
        </h5>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class=""></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav class="mr-50">
        <b-navbar-nav class="ml-auto px-1 ">
          <b-nav-item to="navbar.home" class="py-50 d-block d-lg-none">
            <feather-icon icon="HomeIcon" size="16" class="mr-1 text-black" />
            <span class="text-black">Home</span>
          </b-nav-item>

          <b-nav-item href="#contact" class="py-50 d-block d-lg-none">
            <feather-icon icon="PhoneIcon" size="16" class="mr-1 text-black" />
            <span class="text-black">Contact Us</span>
          </b-nav-item>
        
          <li class="nav-item ml-lg-1 mt-1 mt-lg-0" v-if="hasRole('su') || hasRole('op') || hasRole('op_admin')">
            <b-link class="nav-link font-weight-bolder d-block d-lg-none text-black" :to="{ name: 'AdminDashboard' }">
              <feather-icon icon="UserIcon" size="18" /> &nbsp;{{ $t("navbar.admin") }}
            </b-link>
          </li>
    
          <li class="nav-item ml-lg-1 mt-1 mt-lg-0" v-if="hasRole('fb' || 'fba')">
            <b-link class="nav-link font-weight-bolder d-block d-lg-none" :to="{ name: 'FeedbackLanding' }">
              <feather-icon icon="UserIcon" size="18" /> &nbsp;{{ $t("navbar.dash") }}
            </b-link>
          </li>
          <li  class="nav-item ml-lg-1 mt-1 ">
          <b-nav-item-dropdown
              id="dropdown-grouped"
              variant="link"
              toggle-class="d-flex align-items-center"
              class="dropdown-user"
          >
                    <template #button-content>
                    <a class="ml-50 font-weight-bolder text-black">
                        <feather-icon icon="BookIcon" size="18" />
                        {{ $t("navbar.PolicyLetters") }}
                    </a>
                    </template>
                    <b-dropdown-item @click="hajjUmrah" link-class="d-flex align-items-center">
                    <span>{{ $t("navbar.Hajj_Umrah") }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="FCPS" link-class="d-flex align-items-center">
                    <span>{{ $t("navbar.FCPS") }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="MiscPoliciesCORO" link-class="d-flex align-items-center">
                    <span>{{ $t("navbar.Misc") }}</span>
                    </b-dropdown-item>
                </b-nav-item-dropdown>
            </li>
          <b-nav-item-dropdown v-if="getUser" toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user d-block d-lg-none">
            <template #button-content>
              <div class="d-sm-flex d-none user-nav">
                <p class="user-name mt-4 font-weight-bolder" style="color: #5e5873">
                  {{ getUser ? getUser.name : "" }}
                </p>
                <p class="user-name">
                  {{ getUser ? getUser.role.name : "" }}
                </p>
              </div>
              <b-avatar
                size="40"
                variant="light-primary"
                badge
                :src="getUser ? getUser.profile_image : ''"
                class="badge-minimal"
                badge-variant="success"
              />
            </template>
            <b-dropdown-item v-if="hasRole('su') || hasRole('op') || hasRole('op_admin')" :to="{ name: 'Profile' }" link-class="d-flex align-items-center">
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>Profile</span>
            </b-dropdown-item>
            <b-dropdown-item v-else :to="{ name: 'Profile' }" link-class="d-flex align-items-center">
              <feather-icon size="16" icon="UserIcon" class="mr-50" />
              <span>Profile</span>
            </b-dropdown-item>

            <b-dropdown-divider />

            <b-dropdown-item link-class="d-flex align-items-center" @click="logoutButtonClick">
              <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
              <span>Logout</span>
            </b-dropdown-item>
          </b-nav-item-dropdown>

          <li v-else class="d-block d-lg-none">
            <b-nav-item :to="{ name: 'Login' }">
              <feather-icon icon="LogInIcon" size="15" class="mr-75" />
              {{ $t("navbar.signIn") }}
            </b-nav-item>
          </li>

          <b-collapse id="nav_collapse" is-nav>
            <b-nav class="mx-auto mt-25 d-flex align-items-center">
              <li class="nav-item py-50">
                <a class="nav-link font-weight-bolder" href="#home">
                  <feather-icon icon="HomeIcon" size="18" /> &nbsp;{{ $t("navbar.home") }}</a>
              </li>
             
               
            
              <li class="nav-item ml-lg-1 mt-1 mt-lg-0 py-50" v-if="hasRole('su') || hasRole('op') || hasRole('op_admin')">
                <b-link class="nav-link font-weight-bolder" :to="{ name: 'AdminDashboard' }">
                  <feather-icon icon="UserIcon" size="18" /> &nbsp;{{ $t("navbar.admin") }}
                </b-link>
              </li>
              <li class="nav-item ml-lg-1 mt-1 mt-lg-0 py-50" v-if="hasRole('fb' || 'fba')">
                <b-link class="nav-link font-weight-bolder" :to="{ name: 'FeedbackLanding' }">
                  <feather-icon icon="UserIcon" size="18" /> &nbsp;{{ $t("navbar.dash") }}
                </b-link>
              </li>
              <li class="nav-item ml-lg-1 mt-1 mt-lg-0 py-50">
                <b-link class="phone nav-link scrollactive-item font-weight-bolder" :to="{ name: 'ContactUs' }">
                  <feather-icon icon="PhoneCallIcon" size="18" /> &nbsp;{{ $t("navbar.contactUs") }}
                </b-link>
              </li>
              <b-nav-item-dropdown id="dropdown-grouped" variant="link" class="dropdown-language py-50" right no-caret>
                <template #button-content>
                  <span class="font-weight-bolder text-black">
                    <feather-icon icon="TypeIcon" size="16" />
                    &nbsp;Lang:
                  </span>
                  <span class="ml-50 font-weight-bolder text-black">{{ currentLocale.name }}</span>
                </template>
                <b-dropdown-item v-for="localeObj in locales" :key="localeObj.locale" @click="$i18n.locale = localeObj.locale">
                  <span class="ml-50">{{ localeObj.name }}</span>
                </b-dropdown-item>
              </b-nav-item-dropdown>

              <div class="">
                <b-nav-item-dropdown v-if="getUser" toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user" style="list-style-type: none">
                  <template #button-content>
                    <div class="d-sm-flex d-none user-nav"></div>
                    <b-avatar
                      size="40"
                      variant="light-primary"
                      badge
                      :src="getUser ? getUser.profile_image : ''"
                      class="badge-minimal"
                      badge-variant="success"
                    />
                  </template>
                  <b-dropdown-item v-if="hasRole('su') || hasRole('op') || hasRole('op_admin')" :to="{ name: 'Profile' }" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="UserIcon" class="mr-50" />
                    <span>{{ $t("navbar.Profile") }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item v-else :to="{ name: 'Profile' }" link-class="d-flex align-items-center">
                    <feather-icon size="16" icon="UserIcon" class="mr-50" />
                    <span>Profile</span>
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item link-class="d-flex align-items-center" @click="logoutButtonClick">
                    <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
                    <span>{{ $t("navbar.Logout") }}</span>
                  </b-dropdown-item>
                </b-nav-item-dropdown>
                <span v-else class="nav-item ml-lg-1 mt-2 mt-lg-0">
                  <b-link class="btn btn-primary" :to="{ name: 'Login' }">
                    <feather-icon icon="LogInIcon" size="20" />
                    {{ $t("navbar.signIn") }}
                  </b-link>
                </span>
              </div>
            </b-nav>
          </b-collapse>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { $themeConfig } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import store from "@/store";
export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      appName: "",
      appLogoImage: "",
      appFullName: "",
      activeMainCategoryIndex: 0,
      lastX: 0,
      showMeagaMenu: false,
      lastY: 0,
      direction: "",
      activeMainCategoryTitle: null,
      throttle: false,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
        umrah: "~@/assets/policy_letters/policyHajjUmra.pdf",
      },
       locales: [
        {
          locale: "en",
          img: require("@/assets/images/flags/en.png"),
          name: "English",
        },
        {
          locale: "ur",
          img: require("@/assets/images/flags/ur.png"),
          name: "اردو",
        },
      ],
    };
  },
async mounted() {},
  computed: {
    ...mapGetters({ getUser: "appData/getUser", hasRole: "appData/hasRole" }),
    currentLocale() {
      return this.locales.find((l) => l.locale === this.$i18n.locale);
    },
  },
   watch: {
    activeMainCategoryIndex(_) {
      this.activeMainCategoryTitle =
        this.categoriesList[this.activeMainCategoryIndex].Name;
    },
  },
  created() {
    this.appName = $themeConfig.app.appName;
    this.appFullName = $themeConfig.app.appFullName;
    this.appLogoImage = $themeConfig.app.appLogoImage;
  },
  methods: {
    ...mapActions({ logout: "appData/logout" }),
    async logoutButtonClick() {
      try {
        const res = await this.logout();
        if (res.status === 204) {
          this.$router.push({ name: "Login" });
        }
      } catch (error) {
        error;
      }
    },
     hajjUmrah() {
      window.open("/assets/policy_letters/policyHajjUmra.pdf", "_blank");
    },
    FCPS() {
      window.open(
        "/assets/policy_letters/FCPS&MCPSTrgofCivTrainees.pdf",
        "_blank"
      );
    },
    MiscPoliciesCORO() {
      window.open("/assets/policy_letters/MiscPoliciesCORO.pdf", "_blank");
    },
    changeMegaMenuVisibility(val) {
      this.showMeagaMenu = val;
    },
    hideMegaMenu() {
      this.changeMegaMenuVisibility(false);
    },
    isActive(key) {
      return this.activeMainCategoryIndex === key;
    },

    changeMainCategory(index) {
      this.activeMainCategoryIndex = index;
      this.activeMainCategoryTitle = this.categoriesList[index].Name;
    },
  },
};
</script>

<style>
.bgImg {
  height: 50px;
  margin: 5px;
  margin-left: 10px;
  margin-right: 5px;
}

.nav-link {
  font-weight: bolder;
  color: rgb(0, 0, 0) !important;
  font-family: "Montserrat", Helvetica, Arial, serif;
  margin-right: 35px;
  margin-bottom: 5px;
}

.nav-link.active,
.nav-link:hover {
  background-color: transparent !important;
}

.phone-number {
  direction: ltr;
  unicode-bidi: embed;
}

.text-black {
  color: rgb(0, 0, 0) !important;
}

.dropdown-menu > .show {
  margin-left: 25px !important;
}
</style>