<template>
  <div class="container-fluid" style="background-color: white">
    <b-row class="">
      <b-col lg="4 gx-0" class="left-section mb-1">
        <div class="background-overlay">
          <img
            :src="require('@/assets/images/logo/afp-logo.png')"
            alt="Background Image"
            class="footer__background-img"
          />
        </div>
        <div class="announcements">
          <b-col lg="12" style="height: 98%">
            <h1
              class="font-weight-bolder text-center p-1 text-primary"
              id="app-title"
              style="text-transform: uppercase"
            >
              <strong>{{ $t("navbar.announcements") }}</strong>
            </h1>
            <div class="" style="height: 90%">
              <!-- <dynamic-marquee
                reverse
                hoverPause
                :repeatMargin="400"
                :speed="{ type: 'duration', number: 30000 }"
              >
                <b-card
                  no-body
                  v-for="(item, index) in news"
                  class="overflow-hidden mb-1"
                  :key="index"
                  style="height: calc(100% - 12px)"
                >
                  <b-card-body class="p-50 ml-1 mr-1" style="height: 100%">
                    <b-row
                      class="align-items-center p-0 rounded"
                      style="background-color: #b3d2bb; height: 100%"
                    >
                      <b-col lg="2" md="4" sm="4">
                        <b-img
                          :src="item.image"
                          alt="News image"
                          class="my-img"
                          @click="openAnnouncementModal(item)"
                        ></b-img>
                      </b-col>
                      <b-col lg="1" md="1" sm="1"></b-col>
                      <b-col lg="9" md="7" sm="7" class="mt-1 mt-sm-1 pl-5">
                        <b-card-title
                          class="text-primary mt-1"
                          style="text-align: start"
                          >{{ $t(item.title) }}</b-card-title
                        >
                        <b-card-sub-title
                          class="text-black mb-1"
                          style="color: black !important; text-align: start"
                          v-html="$t(item.description)"
                        ></b-card-sub-title>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </dynamic-marquee> -->

              <b-card
                no-body
                v-for="(item, index) in news"
                class="overflow-hidden"
                style="background-color: white;"
                :key="index"
              >
                <b-card-body class=" p-50 ml-1 mr-1" style="height: 100%">
                  <b-row
                    class="align-items-center p-0 rounded"
                    style="background-color: #b3d2bb; height: 100%"
                  >
                    <b-col lg="2" md="4" sm="4">
                      <b-img
                        :src="item.image"
                        alt="News image"
                        class="my-img"
                        @click="openAnnouncementModal(item)"
                      ></b-img>
                    </b-col>
                    <b-col lg="1" md="1" sm="1"></b-col>
                    <b-col lg="9" md="7" sm="7" class="mt-1 mt-sm-1 pl-5">
                      <b-card-title
                        class="text-primary mt-1"
                        style="text-align: start"
                        >{{ $t(item.title) }}</b-card-title
                      >
                      <b-card-sub-title
                        class="text-black mb-1"
                        style="color: black !important; text-align: start"
                        v-html="$t(item.description)"
                                               ></b-card-sub-title>
                      <a v-if="item.id == 2"  :href="videoUrl" target="_blank" style="font-size: large; font-weight: 200; color: black; border-bottom: 2px solid black;">Click to Watch Booking Video</a>
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
            </div>
          </b-col>
        </div>
      </b-col>

      <b-col lg="8">
        <b-row>
          <b-col md="12" class="right-section-1">
            <div class="background-overlay">
              <img
                :src="require('@/assets/images/logo/afp-logo.png')"
                alt="Background Image"
                class="footer__background-img"
              />
            </div>
            <div
              class="apps justify-content-center p-25"
              ref="carouselContainer"
            >
              <carousel-3d
                :controls-visible="true"
                :autoplay="true"
                :autoplay-timeout="3000"
                :display="3"
                :inverse-scaling="1500"
                :space="800"
                :height="calculatedHeight"
              >
                <slide
                  v-for="(slide, i) in slides"
                  :index="i"
                  :key="i"
                  style="border-radius: 10px"
                  class="slide"
                  @click.native="openModal(slide)"
                >
                  <template
                    slot-scope="{ index, isCurrent, leftIndex, rightIndex }"
                  >
                    <span
                      :class="{
                        current: isCurrent,
                        onLeft: leftIndex >= 0,
                        onRight: rightIndex >= 0,
                      }"
                    >
                      <img
                        id="slide-img"
                        :data-index="index"
                        :src="slide.image"
                        style="height: 100%; width: 100%"
                      />
                      <figcaption
                        style="
                          text-align: center;
                          font-weight: 450;
                          font-size: 1rem;
                        "
                      >
                        {{ slide.caption }}
                      </figcaption>
                    </span>
                  </template>
                </slide>
              </carousel-3d>
            </div>
          </b-col>
          <b-col md="12" class="right-section-2" style="padding-top: 10px">
            <div class="background-overlay">
              <img
                :src="require('@/assets/images/logo/afp-logo.png')"
                alt="Background Image"
                class="footer__background-img"
              />
            </div>
            <div class=" apps h-auto" style="">
              <h1
                class="font-weight-bolder text-primary text-center pt-1"
                id="app-title"
                style="text-transform: uppercase"
              >
                <strong>{{ $t("application.title") }}</strong>
              </h1>
              <b-row class="px-2">
                <b-col  xl="3" md="4" sm="6" cols="6" v-for="data in cards_data" :key="data.id">
                  
                  <span @click="cardSelected(data)">
                    <b-card no-body class="rounded-card">
                      <div class="pe-none card-content">
                        <!-- <h4 class="title">{{ $t(data.application_title) }}</h4> -->
                        <div class="color-row"></div>
                        <!-- <p class="description">{{ $t(data.description) }}</p> -->
                        <p class="additional-text">
                          
                          {{ $t(data.additional_text) }}
                        </p>
                      </div>


                      <div
                        class="card-background"
                      >
                      <img :src="data.img_1_path" class="h-100 w-100" alt="">
                      </div>
                      <div class="black-bg"></div>
                    </b-card>
                  </span>
                </b-col>
               
              </b-row>
              <br>
              
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal
      id="image-modal"
      centered
      hide-footer
      hide-title
      size="md"
      :no-close-on-esc="true"
      :no-close-on-backdrop="false"
      v-if="isModalOpen"
      @hidden="closeModal"
    >
      <div>
        <img
          :src="selectedSlide.image"
          alt="Selected Slide"
          style="width: 100%"
          class="slide-img"
        />
        <p class="slide-caption">{{ selectedSlide.caption }}</p>
      </div>
    </b-modal>

    <b-modal
      id="announcement-modal"
      centered
      hide-footer
      hide-title
      size="md"
      :no-close-on-esc="true"
      :no-close-on-backdrop="false"
      v-if="isAnnouncementModalOpen"
      @hidden="closeAnnouncementModal"
    >
      <div>
        <img
          :src="selectedAnnouncement.image"
          alt="Selected Announcement"
          style="width: 100%"
          class="announcement-img"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";
import { BCard, BCardTitle, BCardBody } from "bootstrap-vue";
import { mapGetters } from "vuex";
import util from "@/util.js"; 
export default {
  components: {
    Carousel3d,
    Slide,
    Swiper,
    SwiperSlide,
    BImg,
    BCard,
    BCardTitle,
    BCardBody,
    
  },
  data() {
    return {
      isModalOpen: false,
      selectedSlide: null,
      calculatedHeight: 0,
      isAnnouncementModalOpen: false,
      selectedAnnouncement: null,
      videoUrl: '/assets/videos/booking.mp4',

      slides: [
        {
          image: require("@/assets/images/banner/bb4b0ee70901b50a1be7d829910a568c.jpg"),
          caption:
            "Quaid-e-Azam Inspecting The First Honour Guard Of The 7/10th Baloch Regiment At Karachi - August 1947",
          borderRadius: "00% 0%",
        },
        {
          image: require("@/assets/images/banner/Ayub&Jinnah_Briskey.jpg"),
          caption:
            "Quaid-e-Azam Mohammad Ali Jinnah decorates Lieutenant Colonel M. Ahmad with the Military Cross for his services in Burma during World War II on March 20, 1948.",
          borderRadius: "0% 10%",
        },
        {
          image: require("@/assets/images/banner/EMnGKhnXsAA_vny.jpg"),
          caption: "Quaid-e-Azam with Major General Nazir Ahmad",
          borderRadius: "10% 10%",
        },
        {
          image: require("@/assets/images/banner/rY5dG2b_5jZ5z5-7.jpg"),
          caption: "Quaid-e-Azam reviews a guard of honour at Peshawar",
          borderRadius: "0% 20%",
        },
        {
          image: require("@/assets/images/banner/gen-ayub.jpg"),
          caption:
            "Gen Ayub Khan Army C-in-C reviews the second Republic Day on 23 March 1958",
          borderRadius: "20% 0%",
        },

        {
          image: require("@/assets/images/banner/maj-aziz.jpg"),
          caption: "Heroes who sacrificed their today for our better tommorow.",
          borderRadius: "0% 10%",
        },
      ],
      news: [
        {
          id: 1,
          image: require("@/assets/images/slider/pma_announcement.jpeg"),
          title: "news.title",
          description: "news.description",
        },
         {
           id: 2,
             image: require("@/assets/images/slider/bookingpic.png"),
             title: "guestroombooking.title",
             description: "guestroombooking.description",
         },
        //  {
        //    id: 3,
        //      image: require("@/assets/videos/booking.mp4"),
        //      title: "guestroombooking.title",
        //      description: "guestroombooking.description",
        //  },

        // {
        //   id: 3,
        //   image: require("@/assets/images/slider/pma_announcement.jpeg"),
        //   title: "news.title",
        //   description: "news.description",
        // },

        // {
        //   id: 4,
        //   image: require("@/assets/images/slider/pma_announcement.jpeg"),
        //   title: "news.title",
        //   description: "news.description",
        // },

        // {
        //   id: 5,
        //   image: require("@/assets/images/slider/pma_announcement.jpeg"),
        //   title: "news.title",
        //   description: "news.description",
        // },

        // {
        //   id: 6,
        //   image: require("@/assets/images/slider/pma_announcement.jpeg"),
        //   title: "news.title",
        //   description: "news.description",
        // },
      ],
      cards_data: [
        {
          id: 1,
          app_name: "Petition",
          link_name: "PETITION",
          // img_1_path: require("@/assets/images/apps/petition.png"),
          // img_2_path: require("@/assets/images/apps/petition.png"),
          // img_1_path: require("@/assets/images/apps/afpCards/petition.png"),
          // img_2_path: require("@/assets/images/apps/afpCards/petition.png"),
          img_1_path: require("@/assets/images/apps/appcard/cards-01.png"),
          img_2_path: require("@/assets/images/apps/appcard/cards-01.png"),
          application_title: "application.petition",
          description: "application.petitionDescription",
          additional_text: "application.petitionText",
          user_types_allowed: [2, 3],
          role: ["su","nu","op"]
        },
        {
          id: 2,
          app_name: "No Objection Certificate",
          link_name: "NOC",
          // img_1_path: require("@/assets/images/apps/noc.png"),
          // img_2_path: require("@/assets/images/apps/noc.png"),
          // img_1_path: require("@/assets/images/apps/afpCards/noc.png"),
          // img_2_path: require("@/assets/images/apps/afpCards/noc.png"),
          img_1_path: require("@/assets/images/apps/appcard/cards-02.png"),
          img_2_path: require("@/assets/images/apps/appcard/cards-02.png"),
          application_title: "application.nocLand",
          description: "application.nocDescription",
          additional_text: "application.nocText",
          user_types_allowed: [2, 3],
          role: ["su","nu","op"]
        },

        {
          id: 3,
          app_name: "Army Help Center",
          // link_name: "MyComplaints",
          // img_1_path: require("@/assets/images/apps/complaint.png"),
          // img_2_path: require("@/assets/images/apps/complaint.png"),
          // img_1_path: require("@/assets/images/apps/afpCards/complaints.png"),
          // img_2_path: require("@/assets/images/apps/afpCards/complaints.png"),
          img_1_path: require("@/assets/images/apps/appcard/cards-04.png"),
          img_2_path: require("@/assets/images/apps/appcard/cards-04.png"),
          application_title: "application.complaint",
          description: "application.complaintDescription",
          additional_text: "application.complaintText",
          user_types_allowed: [2, 3],
          role: ["su","nu","op"]
        },
        //{
        //  id: 4,
        //  app_name: "Pension Management System",
        //  link_name: "PMS",
        //  // img_1_path: require("@/assets/images/apps/pension.png"),
        //  // img_2_path: require("@/assets/images/apps/pension.png"),
        //  // img_1_path: require("@/assets/images/apps/afpCards/pension.png"),
        //  // img_2_path: require("@/assets/images/apps/afpCards/pension.png"),
        //  img_1_path: require("@/assets/images/apps/appcard/cards-06.png"),
        //  img_2_path: require("@/assets/images/apps/appcard/cards-06.png"),
        //  application_title: "application.pms",
        //  description: "application.pensionDescription",
        //  additional_text: "application.pensionText",
        //  user_types_allowed: [2, 3],
        //  role: ["su","nu","op"]
        //},
        {
          id: 5,
          app_name: "Admission Management System",
          // img_1_path: require("@/assets/images/apps/admission.png"),
          // img_2_path: require("@/assets/images/apps/admission.png"),
          // img_1_path: require("@/assets/images/apps/afpCards/admission.png"),
          // img_2_path: require("@/assets/images/apps/afpCards/admission.png"),
          img_1_path: require("@/assets/images/apps/appcard/cards-05.png"),
          img_2_path: require("@/assets/images/apps/appcard/cards-05.png"),
          application_title: "application.ams",
          description: "application.admissionDescription",
          additional_text: "application.admissionText",
          user_types_allowed: [2, 3],
          role: ["su","nu","op"]
        },
        {
          id: 6,
          app_name: "Guest Rooms Booking",
          link_name: "BookingHome",
          // img_1_path: require("@/assets/images/apps/afpCards/booking.png"),
          // img_2_path: require("@/assets/images/apps/afpCards/booking.png"),
          img_1_path: require("@/assets/images/apps/appcard/cards-03.png"),
          img_2_path: require("@/assets/images/apps/appcard/cards-03.png"),
          application_title: "application.GRB",
          description: "application.GRBDescription",
          additional_text: "application.GBRText",
          // user_types_allowed: [1, 2, 3], 
          user_types_allowed: [1, 2],          
          // role: ["su","nu","op","bu","bk__agm","bk__mess_sec","bk__fmn_adm"]
          role: ["su","nu"],
          block_field: "bms_blocked"
        },
         {
           id: 7,
           app_name: "CureMe",
           link_name:"CureMe",
           // img_1_path: require("@/assets/images/apps/afpCards/booking.png"),
           // img_2_path: require("@/assets/images/apps/afpCards/booking.png"),
           img_1_path: require("@/assets/images/apps/appcard/cards-07.png"),
           img_2_path: require("@/assets/images/apps/appcard/cards-07.png"),
           application_title: "application.CureMe",
           description: "application.cureMeText",
           additional_text: "application.CureMeDescription",
           user_types_allowed: [2],          
           role: ["su","nu"],
         },
         {
           id: 8,
           app_name: "CureMe",
           link_name:"JobNoc",
           // img_1_path: require("@/assets/images/apps/afpCards/booking.png"),
           // img_2_path: require("@/assets/images/apps/afpCards/booking.png"),
           img_1_path: require("@/assets/images/apps/appcard/cards-08.png"),
           img_2_path: require("@/assets/images/apps/appcard/cards-08.png"),
           application_title: "application.CureMe",
           description: "application.cureMeText",
           additional_text: "application.JobNocDescription",
           user_types_allowed: [2],          
           role: ["su","nu"],
         },
         {
           id: 9,
           app_name: "ACM",
           link_name:"ACM",
           // img_1_path: require("@/assets/images/apps/afpCards/booking.png"),
           // img_2_path: require("@/assets/images/apps/afpCards/booking.png"),
           img_1_path: require("@/assets/images/apps/appcard/cards-09.png"),
           img_2_path: require("@/assets/images/apps/appcard/cards-09.png"),
           application_title: "application.ACM",
           description: "application.ACMText",
           additional_text: "application.ACMDescription",
           user_types_allowed: [1,2,3],          
           role: ["su","nu"],
         },

      ],
      swiperOptions: {
        slidesPerView: 2,
        spaceBetween: 40,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {},
      },
      swiperOptionsMobile: {
        slidesPerView: 1,
        spaceBetween: 40,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {},
      },
    };
  },
  mixins: [util],
  methods: {
    openAnnouncementModal(announcement) {
      this.selectedAnnouncement = announcement;
      this.isAnnouncementModalOpen = true;
      this.$nextTick(() => {
        this.$bvModal.show("announcement-modal");
      });
    },

    closeAnnouncementModal() {
      this.isAnnouncementModalOpen = false;
      this.selectedAnnouncement = null;
    },

    openModal(slide) {
      this.selectedSlide = slide;
      this.isModalOpen = true;
      this.$nextTick(() => {
        this.$bvModal.show("image-modal");
      });
      
    },

    closeModal() {
      this.isModalOpen = false;
      this.selectedSlide = null;
    },
    calculateCarouselHeight() {
      const availableHeight = window.innerHeight * 0.37;
      return availableHeight;
    },
    adjustCarouselHeight() {
      const containerHeight = this.$refs.carouselContainer.clientHeight;
      const desiredHeight = containerHeight - 35;
      this.calculatedHeight = desiredHeight;
    },
    cardSelected(data) {
      if (data.app_name === "Army Help Center") {
        this.$swal({
          title: 'For general complaints and inquiries, you requested to reach out to our 24/7 helpline at 051-111-130-786',
          timer: 8000,
          showConfirmButton: false,
          icon: 'info',
        });
      }
      if (data.app_name === "Admission Management System") {
        this.$swal({
          title: "Admission Management System Coming Soon",
          timer: 1000,
          icon: "info",
          showConfirmButton: false
        });
      }

      // else if(data.app_name === "Guest Rooms Booking" && this.getUser.role_data.code_name !== "su"){
      //   this.$swal({
      //     title: "From 4th March, users will be able to reserve guest rooms for stays beginning from 18th March onwards.",
      //     icon: "info",
      //     showConfirmButton: true
      //   });
      // }

      else if( data['role'].includes(this.getUser.role_data.code_name) && data['user_types_allowed'].includes(this.getUser.user_type) ){
        if("block_field" in data){
          if(this.getUser[data['block_field']]){
            this.$swal({
              title: "Your Guest Room Bookings have been blocked. Please contact Dir AGM Cell at 8000-37611, 0326-5552240 or 051-5686029.",
              icon: "error",
              showConfirmButton: false
            });
            return            
          }
        }
        this.$router.push({ name: data.link_name });
      }

      else if( !data['role'].includes(this.getUser.role_data.code_name) || !data['user_types_allowed'].includes(this.getUser.user_type ) ){
        this.$swal({
          title: "Not available For this user",
          timer: 1000,
          icon: "error",
          showConfirmButton: false
        });
      }

      // else if (this.getUser) {
      //   this.$router.push({ name: data.link_name });
      // } 
      // else if (data.app_name === "Pension Management System") {
      //   this.$router.push({ name: data.link_name });
      // } 
      else {
        this.$swal({
          title: this.$t("application.error"),
          icon: "error",
          timer: 1000,
          showConfirmButton: false
        });
      }
    },
  },

  mounted() {
    this.adjustCarouselHeight();
    window.addEventListener("resize", this.adjustCarouselHeight);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.adjustCarouselHeight);
  },
  computed: {
    ...mapGetters({
      isLoggedIn: "appData/isLoggedIn",
      getUser: "appData/getUser",
      hasRole: "appData/hasRole",
    }),
  },
};
</script>

<style scoped>
.background-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 90%;
  background-image: url("~@/assets/images/logo/afp-logo.png");
  background-repeat: no-repeat;
  background-position: center center;
  opacity: 0.05;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: contain;
}
.footer__background-img {
  display: none;
}
.left-section {
  height: 85vh !important;
}
.right-section-1 {
  height: 35vh !important;
}
.right-section-2 {
  height: 50vh !important;
}
.apps {
  background: url("~@/assets/images/slider/announcement.png");
  background-size: cover;
  background-color: None !important;
  border-radius: 20px;
  height: 100%;
}
.announcements {
  background: url("~@/assets/images/slider/announcement.png");
  background-size: cover;
  background-color: None !important;
  border-radius: 20px;
  height: 100%;
}

.my-img {
  border-radius: 10%;
  width: auto;
  height: 100px;
}

.carousel-3d-controls .next {
  color: white !important;
}
.carousel-3d-container figcaption {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  bottom: 0;
  position: absolute;
  bottom: 0;
  padding: 15px;
  font-size: 12px;
  min-width: 100%;
  box-sizing: border-box;
}
.rounded-card {
  position: relative;
  padding: 5px;
  border-radius: 10%;
  width: 100%;
  cursor: pointer;
  height: 19vh;
  border: 1px solid #46755a;
  overflow: hidden;
}

.card-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  /* opacity: 1; */
  z-index: 0;
  background-color: rgba(0,0,0,1);
}

.card-background img{
    /* padding: 20px; */
    /* height: 150%; */
    object-fit: cover;
    /* object-position: center; */
}
.card-content {
  position: relative;
  z-index: 2;
  margin-top: auto;
  /* opacity: 1; */
}

.title {
  color: white;
  font-weight: bolder;
  text-transform: uppercase;
}

.description {
  font-size: 14px;
  color: white;
}

/* .color-row {
  width: 50px;
  height: 5px;
  background-color: #46755a;
  margin: 5px 0;
} */

#image-modal div {
  position: relative;
}

.slide-img {
  width: 100%;
  display: block; 
}

.slide-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.7); 
  color: white;
  text-align: center;
  padding: 10px;
}

/* .additional-text {
  max-height: 0;
  overflow: hidden;
  display: none;
  transition: max-height 0.3s ease-in-out;
  color: white;
} */

/* .rounded-card:hover .additional-text {
  max-height: 300px;
  display: block;
} */
.rounded-card:hover .title {
  color: rgb(255, 255, 255) !important;
  font-weight: bolder;
}
.rounded-card:hover .description {
  display: none;
}
/* .rounded-card:hover .additional-text {
  color: rgb(255, 255, 255) !important;
  font-weight: bolder;
} */
/* second */
/* .rounded-card .additional-text {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
  transform-origin: top;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  color: white;
}

.rounded-card:hover .additional-text {
  max-height: 300px;
  transform: rotateX(0deg);
  transition: max-height 0.3s ease, transform 0.5s ease;
}

.rounded-card .additional-text {
  transform: rotateX(90deg);
} */
/* end second */

/* third */
/* .rounded-card .additional-text {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, transform 0.5s cubic-bezier(0.4, 0.2, 0.2, 1);
  transform-origin: top;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  opacity: 0;
  color:white
}

.rounded-card:hover .additional-text {
  max-height: 300px;
  transform: rotateX(0deg);
  opacity: 1;
}

.rounded-card .additional-text {
  transform: rotateX(90deg);
} */
/* third end */

.rounded-card .additional-text {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, transform 0.5s cubic-bezier(0.4, 0.2, 0.2, 1);
  transform-origin: center;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  opacity: 0;
  color: white;
}
.pe-none{
  pointer-events: none;

}
.rounded-card:hover .additional-text {
  max-height: 300px;
  transform: rotateY(0deg);
  opacity: 1;
  
}

.rounded-card .additional-text {
  transform: rotateY(-90deg);
}

/* .rounded-card:hover .card-background {
  opacity: 0.5;
} */
#announcement-modal div {
  position: relative;
}
.announcement-img {
  width: 100%;
  display: block; 
}
/* .content-container {
  flex-grow: 1;
  overflow-y: auto;
} */
@media screen and (max-width: 767px) {
  .right-section-2 {
    height: auto !important; 
  }
 } 
.black-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  /* background-color: rgba(14, 13, 13, 0.5);  */
} 

.black-bg:hover{
  background: rgb(23,37,31);
  background: linear-gradient(5deg, rgba(23,37,31,1) 0%, rgba(82,106,84,0.5186449579831933) 35%, rgba(0,212,255,0) 100%);
}
.video-container {
  width: 100%;
  height: 60%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio (adjust as needed) */
  overflow: hidden;
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
}

.responsive-video {
  position: absolute;
  top: 2.5%;
  left: 2.5%;
  width: 95%;
  height: 95%;
  object-fit: cover; /* Adjust the object-fit value as needed */
}

/* Media query for screens wider than 990px */
@media (min-width: 991px) {
  .responsive-video {
    width: 70% !important;
    height: 70% !important;
    top: 0% !important;
    left: 15% !important;
  }
  .video-container {
    margin-bottom: -10% !important;
  }
}
</style>