<template>
  <section class="section services-section" id="services">
    <div class="container">
      <span v-if="$mq === 'sm'">
        <b-row>
          <b-col lg="12" style="text-align: center">
            <h1
              class="font-weight-bolder text-primary"
              id="app-title"
              style="text-transform: uppercase"
            >
              <strong>{{ $t("application.title") }}</strong>
            </h1>
          </b-col>
          <b-col lg="12">
            <swiper
              class="swiper-responsive-breakpoints"
              :options="swiperOptionsMobile"
            >
              <swiper-slide v-for="data in cards_data" :key="data.id">
                <div class="feature-box" @click="cardSelected(data)">
                  <div class="icon">
                    <span
                      ><b-img
                        :src="data.img_1_path"
                        fluid
                        class="w-100"
                        id="img"
                    /></span>
                  </div>
                  <div class="feature-content">
                    <h5>{{ $t(data.application_title) }}</h5>
                    <p>
                      {{ $t(data.description) }}
                    </p>
                    <p class="additional-text">
                      {{ $t(data.additional_text) }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div slot="pagination" class="swiper-pagination" />
          </b-col>
        </b-row>
      </span>
      <span v-else>
        <b-row class="justify-content-center">
          <!-- <b-col lg="4"></b-col> -->
          <b-col lg="12" style="text-align: center">
            <h1
              class="font-weight-bolder text-primary"
              id="app-title"
              style="text-transform: uppercase"
            >
              <strong>{{ $t("application.title") }}</strong>
            </h1>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col lg="4" class="tst">
            <h1>Hello</h1>
          </b-col> -->
          <b-col lg="12">
            <swiper
              class="swiper-responsive-breakpoints"
              :options="swiperOptions"
            >
              <swiper-slide v-for="data in cards_data" :key="data.id">
                <div class="feature-box" @click="cardSelected(data)">
                  <div class="icon">
                    <span
                      ><b-img
                        :src="data.img_1_path"
                        fluid
                        class="w-100"
                        id="img"
                    /></span>
                  </div>
                  <div class="feature-content">
                    <h5>{{ $t(data.application_title) }}</h5>
                    <p>
                      {{ $t(data.description) }}
                    </p>
                    <p class="additional-text">
                      {{ $t(data.additional_text) }}
                    </p>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div slot="pagination" class="swiper-pagination" />
          </b-col>
        </b-row>
      </span>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { BImg } from "bootstrap-vue";
import "swiper/css/swiper.css";
import { BCard, BCardTitle, BCardBody } from "bootstrap-vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BCard,
    BCardTitle,
    BCardBody,
  },
  data() {
    return {
      cards_data: [
        {
          id: 5,
          app_name: "Admission Management System",
          link_name: "AMS",
          img_1_path: require("@/assets/images/slider/admission.png"),
          img_2_path: require("@/assets/images/slider/admission.png"),
          application_title: "application.ams",
          description: "application.admissionDescription",
          additional_text: "application.admissionText",
        },
        {
          id: 3,
          app_name: "Army Help Center",
          link_name: "Complaint",
          img_1_path: require("@/assets/images/slider/complaint.png"),
          img_2_path: require("@/assets/images/slider/complaint.png"),
          application_title: "application.complaint",
          description: "application.complaintDescription",
          additional_text: "application.complaintText",
        },
        {
          id: 4,
          app_name: "Pension Management System",
          link_name: "PMS",
          img_1_path: require("@/assets/images/slider/pension-icon.png"),
          img_2_path: require("@/assets/images/slider/pms.jpg"),
          application_title: "application.pms",
          description: "application.pensionDescription",
          additional_text: "application.pensionText",
        },
        {
          id: 1,
          app_name: "Petition",
          link_name: "Petitions",
          img_1_path: require("@/assets/images/slider/petition-icon.png"),
          img_2_path: require("@/assets/images/slider/rfa.jpeg"),
          application_title: "application.petition",
          description: "application.petitionDescription",
          additional_text: "application.petitionText",
        },
        {
          id: 2,
          app_name: "No Objection Certificate",
          link_name: "Nocs",
          img_1_path: require("@/assets/images/slider/NOC-icon.png"),
          img_2_path: require("@/assets/images/slider/noc.jpg"),
          application_title: "application.nocLand",
          description: "application.nocDescription",
          additional_text: "application.nocText",
        },
      ],
      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 40,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {},
      },

      swiperOptionsMobile: {
        slidesPerView: 1,
        spaceBetween: 40,
        centeredSlides: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        breakpoints: {},
      },
    };
  },
  computed: {
    ...mapGetters({ getUser: "appData/getUser" }),
  },
  methods: {
    cardSelected(data) {
      if (this.getUser) {
        this.$router.push({ name: data.link_name });
      } else if (
        data.app_name === "Admission Management System" ||
        data.app_name === "Pension Management System"
      ) {
        this.$router.push({ name: data.link_name });
      } else {
        this.$swal({
          title: this.$t("application.error"),
          icon: "error",
          timer: 3000,
          confirmButtonText: this.$t("application.ok"),
        });
      }
    },
  },
};
</script>

<style scoped>
.swiper-container {
  width: 100%;
}
@media (min-width: 768px) {
  .swiper-container {
    overflow: visible;
  }
}
#services {
  background: url("~@/assets/images/slider/app_bg.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: None !important;
  background-blend-mode: multiply;
  height: 40vh;
}

.feature-box {
  background: url("~@/assets/images/slider/login-bg.png");
  padding: 32px;
  box-shadow: 0 0 30px rgba(31, 45, 61, 0.125);
  margin: 35px 0;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  overflow: hidden;
  transition: ease all 0.35s;
  top: 0;
}

.tst {
  background: url("~@/assets/images/slider/login-bg.png");
}

#img {
  height: 60px;
}
.feature-box:hover .icon {
  background: white;
  color: #ffffff;
}

.feature-box .feature-content .additional-text {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.feature-box:hover .feature-content .additional-text {
  max-height: 300px;
}

.feature-content {
  position: relative;
}

.feature-box:hover {
  transform: scale(1.1);
  top: -50px;
}

.feature-box * {
  transition: ease all 0.35s;
}

.feature-box .icon {
  width: 80px;
  height: 80px;
  line-height: 70px;
  text-align: center;
  border-radius: 50%;
  margin-bottom: 22px;
  font-size: 27px;
}

.feature-box .icon i {
  line-height: 70px;
}

.swiper-container {
  width: 100%;
  overflow: hidden;
}

.feature-box h5 {
  color: #094120;
  font-weight: 600;
}

.feature-box p {
  margin: 0;
}

.feature-box:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: auto;
  right: 0;
  border-radius: 10px;
  width: 0;
  background: #094019;
  z-index: -1;
  transition: ease all 0.35s;
}

.feature-box:hover {
  top: -5px;
}

.feature-box:hover h5 {
  color: #ffffff;
}

.feature-box:hover p {
  color: rgba(255, 255, 255, 0.8);
}

.feature-box:hover:after {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  left: 0;
  right: auto;
}

.section {
  padding: 50px 0;
  position: relative;
}

.section-title {
  padding-bottom: 45px;
}

.section-title h2 {
  font-weight: 700;
  color: #094120;
  font-size: 45px;
  margin: 0 0 15px;
  border-left: 5px solid #094120;
  padding-left: 15px;
}
</style>
