<template>
  <section id="newsEvent" class="pt-3 ">
    <b-container fluid>
      <!-- <h4 class="display-4 font-weight-bolder text-primary text-center mb-3">
        {{ $t("newsEvent.title") }}
      </h4> -->
      <span v-if="$mq === 'sm'">
        <b-card-group>
        <!-- <b-row
        class="d-flex align-items-center justify-content-center flex-nowrap match-height"
        align-h="between"
      >
        <b-col md="12" sm="12" style="height: 460px">
          <b-card no-body style="background-color: #cbe0d1">
            <b-card-title
              class="text-white rounded p-1 font-weight-bolder text-center bg-primary"
            >
              {{ $t("newsEvent.latestNews") }}
            </b-card-title>
            <div class="h-100 w-100">
              <b-card-body no-body class="p-2 w-100 h-100">
                <dynamic-marquee
                  reverse
                  hoverPause
                  :repeatMargin="400"
                  :speed="{ type: 'duration', number: 8000 }"
                >
                  <b-card
                    no-body
                    v-for="(item, index) in news"
                    class="overflow-hidden mb-1"
                    :key="index"
                    :class="index % 2 !== 0 ? 'bg-white' : 'bg-light'"
                    border-variant="light"
                    style="background-color: whitesmoke !important"
                  >
                    <b-card-body class="p-50 ml-1 mr-1">
                      <b-row
                        class="align-items-center p-0 rounded"
                        style="background-color: #b3d2bb"
                      >
                        <b-col sm="2">
                          <b-img
                            :src="item.image"
                            alt="News image"
                            class="my-img"
                            width="80px"
                          ></b-img>
                        </b-col>
                        <b-col sm="10" class="mt-1 mt-sm-0">
                          <b-card-title class="text-primary">
                            {{ item.title }}
                          </b-card-title>
                          <b-card-sub-title
                            class="text-white"
                            style="color: white !important"
                          >
                            {{ item.description }}
                          </b-card-sub-title>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </dynamic-marquee>
              </b-card-body>
            </div>
          </b-card>
        </b-col>
        </b-row> -->
        <b-row
        class="d-flex align-items-center justify-content-center flex-nowrap match-height"
        align-h="between"
      >
        <b-col md="12" sm="12">
          <ApplicationSwiper2Vue />
        </b-col>
      </b-row>
    </b-card-group>
      </span>
      <span v-else>
        <b-row
        class="d-flex align-items-center justify-content-center flex-nowrap match-height"
        align-h="between"
      >
      
        <!-- <b-col md="4" sm="12" style="height: 460px">
          <b-card no-body style="background-color: #cbe0d1">
            <b-card-title
              class="text-white rounded p-1 font-weight-bolder text-center bg-primary"
            >
              {{ $t("newsEvent.latestNews") }}
            </b-card-title>
            <div class="h-100 w-100">
              <b-card-body no-body class="p-2 w-100 h-100">
                <dynamic-marquee
                  reverse
                  hoverPause
                  :repeatMargin="400"
                  :speed="{ type: 'duration', number: 8000 }"
                >
                  <b-card
                    no-body
                    v-for="(item, index) in news"
                    class="overflow-hidden mb-1"
                    :key="index"
                    :class="index % 2 !== 0 ? 'bg-white' : 'bg-light'"
                    border-variant="light"
                    style="background-color: whitesmoke !important"
                  >
                    <b-card-body class="p-50 ml-1 mr-1">
                      <b-row
                        class="align-items-center p-0 rounded"
                        style="background-color: #b3d2bb"
                      >
                        <b-col sm="2">
                          <b-img
                            :src="item.image"
                            alt="News image"
                            class="my-img"
                            width="80px"
                          ></b-img>
                        </b-col>
                        <b-col sm="10" class="mt-1 mt-sm-0">
                          <b-card-title class="text-primary">
                            {{ item.title }}
                          </b-card-title>
                          <b-card-sub-title
                            class="text-white"
                            style="color: white !important"
                          >
                            {{ item.description }}
                          </b-card-sub-title>
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </dynamic-marquee>
              </b-card-body>
            </div>
          </b-card>
        </b-col> -->
        <b-col lg="12" md="8" sm="12" style="margin: 0; padding: 0;">
          <!-- <ApplicationSwiperVue /> -->
          <ApplicationSwiper2Vue />
        </b-col>
      </b-row>
      </span>
    </b-container>
  </section>
</template>

<script>
// import ApplicationSwiperVue from "../Swipers/ApplicationSwiper.vue";
import ApplicationSwiper2Vue from "../Swipers/ApplicationSwiper2.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    // ApplicationSwiperVue,
    ApplicationSwiper2Vue,
  },
  data() {
    return {
      news: [
        {
          id: 1,
          image: require("@/assets/images/slider/03.jpg"),
          title: "No Enmity with Pakistan's People",
          description:
            "Former Chief Minister and Punjab President Capt. Sarfaraz Ahmed ...",
        },
        {
          id: 2,
          image: require("@/assets/images/slider/03.jpg"),
          title: "Pakistan Army",
          description:
            "Former Chief Minister and Punjab President Capt. Sarfaraz Ahmed ...",
        },
        {
          id: 3,
          image: require("@/assets/images/slider/03.jpg"),
          title: "Pakistan Army Shuhada",
          description:
            "Former Chief Minister and Punjab President Capt. Sarfaraz Ahmed ...",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({ isLoggedIn: "appData/isLoggedIn" }),
  },
};
</script>

<style scoped>
#newsEvent {
  background-color: #e4efe7;
}
.my-img {
  border-radius: 8%;
}
</style>
