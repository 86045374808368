<template>
  <div class="d-flex flex-column mb-2" style="min-height: 100vh; background: white;  overflow: hidden; ">
    <Tb class="flex-shrink-0 mt-2" style="height: 6vh;"/>
    <Navbar class="flex-shrink-0 mt-0" style="height: 8vh; " />
    <Content class="flex-grow-1 mb-1"/>
    <Footer class="flex-shrink-0 mt-1" />
  </div>
</template>

  
  <script>
  // import TopNavbar from "@/components/home/TopNavbar.vue";
  // import Navbar2 from "@/components/home/Navbar2.vue";
  import NewTb from "@/components/newHome/NewTb.vue";
  import Navbar from "@/components/newHome/Nav.vue";
  // import Banner from "@/components/home/Banner.vue";
  // import Application from "@/components/home/Application.vue";
  import NewsEvent from "@/components/newHome/NewsEvent.vue";
  import ApplicationSwiper from "@/components/newHome/AppSwiperTest.vue";
  import Contact from "@/components/newHome/Contact.vue";
  // import Footer from "@/components/home/Footer.vue";
  // import TopBar from "@/components/newHome/Topbar.vue";
  import Tb from "@/components/newHome/Tb.vue";
  // import Banner from "@/components/newHome/Banner.vue";
  import Banner from "@/components/newHome/BannerNew.vue"
  import Guide from "@/components/newHome/Guide.vue";
  import Footer from "@/components/newHome/Footer.vue"
  // import Marquee from "@/components/newHome/Marquee.vue"
  import Content from '@/components/newHome/Content.vue';
  
  // import GalleryLightBox from "@/components/home/GalleryLightBox.vue";
  
  export default {
    name: "Home",
    components: {
      // TopNavbar,
      Banner,
      // Application,
      NewsEvent,
      ApplicationSwiper,
      Contact,
      Footer,
      Navbar,
      Guide,
      Content,
      // Marquee,
      // TopBar,
      Tb,
      NewTb,
      // GalleryLightBox,
    },
  };
  </script>
  <style scoped>
/* @media screen and (max-width: 767px) {
  .d-flex {
    min-height: auto; 
  }
} */

  </style>
  